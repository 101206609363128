import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  borders,
  boxShadow,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import useSiteMetadata from "../components/SiteMetadata";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";
import CheckMark from "../components/CheckmarkCircle";
import HieraArchyWebIcon from "../components/HierarchyWebIcon";
import TimerStopWatchIcon from "../components/TimerStopWatchIcon";
import MoneyBalanceIcon from "../components/MoneyBalanceIcon";
import TheIllustration from "../components/TheIllustration";
import StreamlineCheckMark from "../components/StreamlineCheckMark";

const TopInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes.lg};
  text-align: center;
  position: relative;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: 0 ${sizes.lg};
  }
`;

const MainHeader = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["6xl"]};
  margin: ${sizes.md} 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["4xl"]};
  }
`;

const MainTagLine = styled.h3`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.xl};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes.xl} auto;
  }
`;

const InputGroup = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto ${sizes["2xl"]} auto;
  display: flex;
  align-items: baseline;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const CtaInput = styled.input`
  border: ${borders.regular};
  padding: ${sizes.md} ${sizes.xl};
  color: ${colorAliases.textColor};
  border-radius: ${borderRadius.md};
  margin: 0 ${sizes.md} 0 0;
  font-size: ${fontSizes.lg};
  width: 100%;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
    margin: 0 0 ${sizes.sm} 0;
  }
`;

const CtaButton = styled.button`
  background: transparent;
  border-radius: ${borderRadius.md};
  border: 1px solid ${colorAliases.red};
  color: ${colorAliases.red};
  padding: ${sizes.sm} ${sizes.md};
  font-size: ${fontSizes.lg};
  cursor: pointer;
  margin: ${sizes.sm} 0 ${sizes["2xl"]} 0;
  border: 1px solid ${colorAliases.red};
  box-shadow: ${boxShadow.md};
`;

const AppImage = styled.div`
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto ${sizes["2xl"]} auto;
`;

export const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.sm} ${sizes.lg};
  }
`;

const SectionHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
    margin-bottom: ${sizes.xs};
  }
`;

const SectionTagline = styled.h4`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes.xl} auto;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto ${sizes["2xl"]} auto;
  box-shadow: ${boxShadow.xl};
`;

const SideBySide = styled.div`
  display: flex;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;
const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Half = styled.div`
  width: 50%;
  padding: ${(props) => props.padding || 0};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
    padding: ${(props) => props.paddingMobile || props.padding || 0};
  }
`;

const ImageRight = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 0 0 auto;
  box-shadow: ${boxShadow.xl};
`;

const ImageLeft = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 0 0;
  box-shadow: ${boxShadow.xl};
`;

const Features = styled.ul`
  text-align: left;
  margin: ${sizes.lg} 0 ${sizes.xl} 0;
`;

const Feature = styled.li`
  list-style: none;
  font-weight: 400;
  font-size: ${fontSizes.xl};
  margin-bottom: ${sizes.md};
  display: flex;
  align-items: baseline;

  & span {
    margin-left: ${sizes.sm};
  }
`;

const CheckMarkIcon = styled(StreamlineCheckMark)`
  width: 20px;
`;

const Highlight = styled.span`
  color: ${colorAliases.red};
`;

const Steps = styled.div`
  position: relative;
`;
const Dashes = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 4px);
  width: 4px;
  border-right: 1px dashed ${colors["gray"]["200"]};
`;

const Step = styled.div`
  display: flex;
`;
const Number = styled.div`
  border-radius: ${borderRadius.full};
  border: 1px solid ${colorAliases.red};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const StepCard = styled.div`
  text-align: ${(props) => props.right || `left`};
`;

const ImageNoMargin = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0;
  box-shadow: ${boxShadow.xl};
`;

const StepHeader = styled.h4`
  font-weight: 700;
  font-size: ${fontSizes.xl};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 0 ${sizes.xs} 0;
  }
`;

const StepText = styled.p`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 0 ${sizes.md} 0;
  }
`;

const Done = styled.div`
  border-radius: ${borderRadius.full};
  border: 3px solid ${colorAliases.turqoise};
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const DoneCheckMark = styled(CheckMark)`
  width: 40px;
  height: 40px;
`;

const FeatureCards = styled.div`
  display: flex;
  justify-content: center;

  &:last-of-type {
    margin-bottom: ${sizes["2xl"]};
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  width: 30%;
  max-width: 300px;
  padding: 0 ${sizes.xl};
  margin-right: ${sizes["3xl"]};

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: flex;
    text-align: left;
    margin-right: 0;
    width: 100%;
    padding: 0;
    justify-content: space-between;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const logoBase = css`
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto ${sizes.md} auto;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 40px;
    height: 40px;
    margin: 0 0 ${sizes.md} 0;
  }
`;

const HieraArchyWebIconWrapper = styled(HieraArchyWebIcon)`
  ${logoBase}
`;
const TimerStopWatchIconWrapper = styled(TimerStopWatchIcon)`
  ${logoBase}
`;
const MoneyBalanceIconWrapper = styled(MoneyBalanceIcon)`
  ${logoBase}
`;
const TheIllustrationWrapper = styled(TheIllustration)`
  width: 80%;
  margin: -${sizes["3xl"]} auto -${sizes["5xl"]} auto;
`;

const FeatureCardTextBox = styled.div`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
    margin-left: ${sizes.md};
  }
`;

const FeatureHeading = styled.h4`
  font-weight: 700;
  margin-bottom: ${sizes.md};
  font-size: ${fontSizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes.md};
    margin: 0 0 0 0;
  }
`;
const FeatureText = styled.p`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 0 ${sizes.md} 0;
  }
`;

export const IndexPageTemplate = ({
  location,
  toparea,
  topareaImage,
  oldways,
  oldwaysImage,
  solution,
  solutionImage,
}) => {
  const { siteUrl, title } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={siteUrl + location.pathname} />
        <title>{`${title} - Arbeidsflyt og e-signaturer i ett`}</title>
        <meta
          property="og:image"
          content="https://dflow.com/img/dflow-og-home.jpg"
        />
      </Helmet>
      <OuterContainer>
        <TopInnerContainer>
          <MainHeader>{toparea.title}</MainHeader>
          <MainTagLine>{toparea.subtitle}</MainTagLine>

          {/* <InputGroup>
            <CtaInput placeholder="Din epost..."></CtaInput>
            <CtaButton>Kom igang</CtaButton>
          </InputGroup> */}

          <AppImage src={`/img/${topareaImage}`}>
            <TheIllustrationWrapper
              color={colorAliases.turqoise}
            ></TheIllustrationWrapper>
          </AppImage>
        </TopInnerContainer>
      </OuterContainer>

      {/* <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>{oldways.title}</SectionHeader>
          <SectionTagline>{oldways.subtitle}</SectionTagline>
  
          <Image src={`/img/${oldwaysImage}`}></Image>
        </SectionInnerContainer>
      </OuterContainer> */}

      <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>{solution.title}</SectionHeader>

          <Centered>
            <Features>
              <Feature>
                {" "}
                <CheckMarkIcon color={colorAliases.textColor}></CheckMarkIcon>
                <span>
                  Slutt på mellomlagring, scanning og PDF konvertering
                </span>
              </Feature>
              <Feature>
                <CheckMarkIcon color={colorAliases.textColor}></CheckMarkIcon>
                <span>
                  Integrasjon mot dib, med tilgang til 2000+ dokumentmaler
                </span>
              </Feature>
              <Feature>
                <CheckMarkIcon color={colorAliases.textColor}></CheckMarkIcon>
                <span>Redigering og samarbeid i Word Online</span>
              </Feature>
              <Feature>
                <CheckMarkIcon color={colorAliases.textColor}></CheckMarkIcon>
                <span>Sikker signering gjennom BankID</span>
              </Feature>
            </Features>
          </Centered>
        </SectionInnerContainer>
      </OuterContainer>

      <OuterContainer backGroundColor={colorAliases.beige}>
        <SectionInnerContainer>
          <SectionHeader>dflow i 3 enkle steg.</SectionHeader>
          <SectionTagline>Få dokumentene signert i rekordfart.</SectionTagline>

          <Steps>
            <Dashes></Dashes>

            <Step>
              <Half padding={`0 ${sizes.xl} ${sizes.xl} 0`}>
                <ImageRight src="/img/frontpage.png"></ImageRight>
              </Half>

              <Half padding={`0 0 ${sizes.xl} ${sizes.xl}`}>
                <Number>
                  <span>1</span>
                </Number>

                <StepCard>
                  <StepHeader>Alt kan signeres</StepHeader>
                  <StepText>
                    Send dokumenter rett fra dib på bedriftens egen brevmal
                    eller last opp hvilket som helst Word/PDF-dokument.
                    Dashboardet i dflow gir deg løpende status på dine
                    signaturoppdrag.
                  </StepText>
                </StepCard>
              </Half>
            </Step>

            <Step>
              <Half padding={`0 ${sizes.xl} ${sizes.xl} 0`}>
                <Number>
                  <span>2</span>
                </Number>

                <StepCard right="right">
                  <StepHeader>Rediger i skyen</StepHeader>
                  <StepText>
                    Rediger dokumentene direkte i dflow med Word Online
                    integrasjonen. Samarbeid med kolleger før dokumentet
                    ferdigstilles. Ikke tenk på å konvertere til PDF. Det gjør
                    dflow automatisk.
                  </StepText>
                </StepCard>
              </Half>

              <Half padding={`0 0 ${sizes.xl} ${sizes.xl}`}>
                <ImageLeft src="/img/edit.png"></ImageLeft>
              </Half>
            </Step>

            <Step>
              <Half padding={`0 ${sizes.xl} ${sizes.xl} 0`}>
                <ImageRight src="/img/sign.png"></ImageRight>
              </Half>

              <Half padding={`0 0 ${sizes.xl} ${sizes.xl}`}>
                <Number>
                  <span>3</span>
                </Number>

                <StepCard>
                  <StepHeader>Effektiv signering</StepHeader>
                  <StepText>
                    Send ett eller flere dokumenter i en operasjon. Mottakere
                    signerer med BankID eller Inksign, og får automatiske
                    påminnelser. Som avsender får du varslinger om status og
                    hvem som har signert.
                  </StepText>
                </StepCard>
              </Half>
            </Step>
            <Done>
              <DoneCheckMark color={colorAliases.turqoise}></DoneCheckMark>
            </Done>
          </Steps>
          {/* <CtaButton
            style={{
              marginTop: sizes["3xl"],
              marginBottom: sizes["2xl"],
              width: 200,
            }}
          >
            Kom igang
          </CtaButton> */}
        </SectionInnerContainer>
      </OuterContainer>

      <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>Vil du vite mer?</SectionHeader>
          <CtaButton>Kontakt oss</CtaButton>

          <FeatureCards>
            <FeatureCard>
              <HieraArchyWebIconWrapper
                color={colorAliases.turqoise}
              ></HieraArchyWebIconWrapper>
              <FeatureCardTextBox>
                <FeatureHeading>Fullintegrert</FeatureHeading>
                <FeatureText>
                  dib, Word Online og Signicat gjør arbeidsflyten knirkefri.
                </FeatureText>
              </FeatureCardTextBox>
            </FeatureCard>

            <FeatureCard>
              <MoneyBalanceIconWrapper
                color={colorAliases.turqoise}
              ></MoneyBalanceIconWrapper>
              <FeatureCardTextBox>
                <FeatureHeading>Betal kun for bruk </FeatureHeading>
                <FeatureText>
                  Ingen dyre abonnement eller begrensning på brukere. Betal per
                  signatur.
                </FeatureText>
              </FeatureCardTextBox>
            </FeatureCard>

            <FeatureCard>
              <TimerStopWatchIconWrapper
                color={colorAliases.turqoise}
              ></TimerStopWatchIconWrapper>
              <FeatureCardTextBox>
                <FeatureHeading>Fjern tidstyver </FeatureHeading>
                <FeatureText>
                  Automatiske påminnelser, pdf-konvertering og lagring av dine
                  kontakter.
                </FeatureText>
              </FeatureCardTextBox>
            </FeatureCard>
          </FeatureCards>
        </SectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout language={frontmatter.language}>
      <IndexPageTemplate
        location={location}
        toparea={frontmatter.toparea}
        topareaImage={frontmatter.toparea.image.relativePath}
        oldways={frontmatter.oldways}
        oldwaysImage={frontmatter.oldways.image.relativePath}
        solution={frontmatter.solution}
        solutionImage={frontmatter.solution.image.relativePath}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        language
        toparea {
          title
          subtitle
          image {
            relativePath
          }
        }
        oldways {
          title
          subtitle
          image {
            relativePath
          }
        }
        solution {
          title
          image {
            relativePath
          }
        }
      }
    }
  }
`;
