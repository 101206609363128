import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
    className={className}
    onClick={onClick}
  >
    <g transform="matrix(10.416666666666666,0,0,10.416666666666666,0,0)">
      <defs>
        <style>{`.ab{fill: ${color};fill-rule: evenodd}`}</style>
      </defs>
      <g>
        <path
          d="M20.06,12.45a.35.35,0,0,0-.26.41A6.88,6.88,0,0,1,19.37,17a8.59,8.59,0,0,1-2.55,3.34,11.7,11.7,0,0,1-6.41,2.53A8.79,8.79,0,0,1,4,21a7,7,0,0,1-2.44-5.64A10.19,10.19,0,0,1,3.71,9.26a7.93,7.93,0,0,1,5-3.11,4.11,4.11,0,0,1,3.56,1.32.29.29,0,0,0,.42,0,.3.3,0,0,0,0-.42A4.76,4.76,0,0,0,8.65,5.43,8.81,8.81,0,0,0,3,8.67,11.06,11.06,0,0,0,.46,15.29a8,8,0,0,0,2.81,6.56A9.86,9.86,0,0,0,10.5,24a12.61,12.61,0,0,0,6.89-2.9A9.39,9.39,0,0,0,20.1,17.3a7.59,7.59,0,0,0,.37-4.59A.34.34,0,0,0,20.06,12.45Z"
          class="ab"
        ></path>
        <path
          d="M23.2,0c-.24,0-.2.09-.86,1-1.05,1.5-3.41,4.63-5.63,7.67-1.6,2.17-3.13,4.3-4.17,5.67a12.59,12.59,0,0,1-1.15,1.42c-.07.06-.16,0-.26,0a2.76,2.76,0,0,1-.8-.43,18.37,18.37,0,0,1-2.84-2.93c-.35-.43-.45-.69-.68-.51s-.35.16-.2.38l.21.3a24.85,24.85,0,0,0,2.31,2.82,5.26,5.26,0,0,0,1.63,1.22A1.22,1.22,0,0,0,12,16.57a18.55,18.55,0,0,0,2.16-2.46c.82-1.06,1.81-2.38,2.81-3.78,2.35-3.29,4.81-7,5.94-8.79a6.77,6.77,0,0,0,.67-1.2A.35.35,0,0,0,23.2,0Z"
          class="ab"
        ></path>
      </g>
    </g>
  </svg>
);
