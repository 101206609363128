import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
    className={className}
    onClick={onClick}
  >
    <g transform="matrix(10.416666666666666,0,0,10.416666666666666,0,0)">
      <defs>
        <style>{`.a{fill: ${color};fill-rule: evenodd}`}</style>
      </defs>
      <g>
        <path
          d="M6.85,13.9a1.1,1.1,0,0,0-.64-.82,3.54,3.54,0,0,0-1.42-.16,1.62,1.62,0,0,1-.45,0,.2.2,0,0,1-.15-.07c-.14-.28,0-.47.27-.58a2.17,2.17,0,0,1,1.2-.15.28.28,0,0,0,.15-.54,2.42,2.42,0,0,0-.56-.11h0a1.11,1.11,0,0,0,0-.19.63.63,0,0,0,0-.19c-.18-.67-.28-.76-.46-.78s-.28-.07-.54.76a.71.71,0,0,0,0,.2.69.69,0,0,0,0,.2l0,.08a1.84,1.84,0,0,0-.41.16,1.12,1.12,0,0,0-.55,1.5,1.08,1.08,0,0,0,.72.69A4.85,4.85,0,0,0,5.38,14c.19,0,.41-.05.46.15s-.17.39-.42.51a1.93,1.93,0,0,1-1.13.19A.78.78,0,0,1,4,14.73a2.55,2.55,0,0,1-.3-.26.32.32,0,0,0-.45,0,.3.3,0,0,0,0,.44,2.34,2.34,0,0,0,.44.45,1.56,1.56,0,0,0,.43.21,2,2,0,0,0,.26,0,.78.78,0,0,0,0,.16.63.63,0,0,0,0,.19c.18.67.28.76.47.78s.27.07.53-.76a.71.71,0,0,0,0-.2.68.68,0,0,0,0-.2h0a3.09,3.09,0,0,0,.69-.22A1.3,1.3,0,0,0,6.85,13.9Z"
          class="a"
        ></path>
        <g>
          <path
            d="M9.12,15.22a4.66,4.66,0,0,0,.16-3.3A3.51,3.51,0,0,0,6.91,9.66a.32.32,0,0,0-.4.2.32.32,0,0,0,.2.4,2.87,2.87,0,0,1,1.85,1.89,3.85,3.85,0,0,1-.25,2.7,3.78,3.78,0,0,1-1.84,1.8,4.72,4.72,0,0,1-2.6.4,3.1,3.1,0,0,1-2.74-2.12A3.89,3.89,0,0,1,2,11.34a3.86,3.86,0,0,1,1.67-1.28A4.93,4.93,0,0,1,5.8,9.8a.27.27,0,0,0,.31-.25.27.27,0,0,0-.25-.3,4.15,4.15,0,0,0-2.45.17,4.33,4.33,0,0,0-2.07,1.41A4.73,4.73,0,0,0,.15,15.22a4.05,4.05,0,0,0,3.59,2.89,5.76,5.76,0,0,0,3.17-.55A4.6,4.6,0,0,0,9.12,15.22Z"
            class="a"
          ></path>
          <path
            d="M20.1,2.11c-2.11.59-4.28,1.1-6.47,1.64l-.34.08a1.93,1.93,0,0,0,.11-.34,1.64,1.64,0,0,0-.11-1,2,2,0,0,0-1-1A2.39,2.39,0,0,0,11,1.34,2.38,2.38,0,0,0,9.25,2.77a1.84,1.84,0,0,0,.28,2l-.23.06c-2.42.62-4.81,1.31-7.17,2a.28.28,0,0,0-.21.33.28.28,0,0,0,.34.21l2-.39a2.93,2.93,0,0,0-.12.53,1.13,1.13,0,0,0,0,.32s0,1.11.59,1.11.48-1.18.48-1.18,0-.2,0-.3a5.48,5.48,0,0,0-.22-.61C6.15,6.56,7.4,6.31,8.65,6c.84-.18,1.67-.38,2.51-.59C11.06,7.17,11,8.9,11,10.64c0,1.14,0,2.28.06,3.42,0,1.63.09,3.25.06,4.87H11c-.55,0-1.08.12-1.62.19s-1.07.17-1.6.28a.26.26,0,0,0-.23.32.28.28,0,0,0,.32.23c.53-.07,1.06-.13,1.58-.17s1.06-.06,1.59-.07h1c.34,0,.68,0,1,.07a8.53,8.53,0,0,1,1,.12,1.19,1.19,0,0,1,.48.17.48.48,0,0,1,.21.55.82.82,0,0,1-.44.59,3,3,0,0,1-.68.2c-.41.07-.83.09-1.23.13-.66.07-1.33.16-2,.22-.44,0-.88.07-1.31.08a5,5,0,0,1-.77,0A1.18,1.18,0,0,1,8,21.72a.65.65,0,0,1-.36-.53.79.79,0,0,1,.27-.65.31.31,0,0,0,0-.44.32.32,0,0,0-.45-.05,1.48,1.48,0,0,0-.56,1.16,1.35,1.35,0,0,0,.67,1.16,2,2,0,0,0,.64.23,5.55,5.55,0,0,0,.89.08c.46,0,.92,0,1.37,0,.68,0,1.35-.07,2-.1.43,0,.89,0,1.33-.11a3.64,3.64,0,0,0,1-.28,1.88,1.88,0,0,0,1-1.35,1.54,1.54,0,0,0-.69-1.63,2,2,0,0,0-.67-.26,8.17,8.17,0,0,0-1.36-.09q-.43,0-.87,0l-.48,0c.18-1.79.3-3.59.31-5.4,0-1.14,0-2.29-.06-3.43-.06-1.59-.19-3.17-.25-4.76l.47-.12h.07l.06,0,1.7-.46c1.41-.4,2.8-.83,4.17-1.28a1.71,1.71,0,0,0,0,.32,2.23,2.23,0,0,0,.05.36c.21,1.19.39,1.14.53,1.13s.33-.09.47-1.17a2.17,2.17,0,0,0,0-.36,1.47,1.47,0,0,0,0-.36,2,2,0,0,0-.07-.23l1.11-.37a.31.31,0,1,0-.19-.6ZM12.58,3.53a2,2,0,0,1-.17.52l-1.93.47a.69.69,0,0,1-.22-.16,1,1,0,0,1-.07-1.17,1.42,1.42,0,0,1,1-.8,1.39,1.39,0,0,1,.78.06,1.09,1.09,0,0,1,.57.47A1,1,0,0,1,12.58,3.53Z"
            class="a"
          ></path>
        </g>
        <g>
          <path
            d="M23.73,12.38a60.11,60.11,0,0,0-6.32,0,16.71,16.71,0,0,0-3.6.49.28.28,0,0,0-.25.31.32.32,0,0,0,.1.18.19.19,0,0,0-.05.11,3.06,3.06,0,0,0,1.24,2.75,5.25,5.25,0,0,0,2,1,6.38,6.38,0,0,0,2.27.19,4.83,4.83,0,0,0,3-1.62A6.39,6.39,0,0,0,23.57,13h.06a.32.32,0,0,0,.37-.26A.34.34,0,0,0,23.73,12.38Zm-2.2,2.89a3.93,3.93,0,0,1-2.48,1.16,6.06,6.06,0,0,1-1.92-.13,5.06,5.06,0,0,1-1.78-.7,2.39,2.39,0,0,1-1.18-2.06s0,0,0-.08c1.2-.06,2.44-.12,3.69-.21l1.94-.17A24.71,24.71,0,0,1,22.9,13,5.58,5.58,0,0,1,21.53,15.27Z"
            class="a"
          ></path>
          <path
            d="M16.68,11.66c.08-.11.29-.58.38-.69a11.42,11.42,0,0,1,.77-1,.3.3,0,0,0,.21.21,1.92,1.92,0,0,0,1.68-.32,3.3,3.3,0,0,1,.64.6c.12.14.47.63.58.79s0,.09.08.14c.48.65.86.57,1.15,0v0a6.49,6.49,0,0,0-.58-1,2.74,2.74,0,0,0-.52-.6,2.77,2.77,0,0,0-.76-.45,3.71,3.71,0,0,0,.33-.54,2.12,2.12,0,0,0-.92-3,2.91,2.91,0,0,0-2.48.31,2.18,2.18,0,0,0-1.16,2.2,1.85,1.85,0,0,0,.51.93,3.16,3.16,0,0,0,.64.44,5.41,5.41,0,0,0-.9.55,3,3,0,0,0-.42.45,2.38,2.38,0,0,0-.31.54c0,.13-.21.62-.24.76C15.37,12.35,15.75,12.32,16.68,11.66Zm.55-2.74a1.05,1.05,0,0,1-.35-.7A1.33,1.33,0,0,1,17.75,7a1.94,1.94,0,0,1,1.57-.21c.69.25.74,1,.51,1.64,0,0-.55,1.37-1.64,1.16h0A.24.24,0,0,0,18,9.45,3.13,3.13,0,0,1,17.23,8.92Z"
            class="a"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
