import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
    className={className}
    onClick={onClick}
  >
    <g transform="matrix(10.416666666666666,0,0,10.416666666666666,0,0)">
      <defs>
        <style>{`.a{fill: ${color};fill-rule: evenodd}`}</style>
      </defs>
      <g>
        <g>
          <path
            d="M1.61,9a3.15,3.15,0,0,0,1.94.14A2,2,0,0,0,4.87,7.89,2.6,2.6,0,0,0,4.54,5.5,2.07,2.07,0,0,0,3.2,4.72,2.62,2.62,0,0,0,.39,6.13,2.76,2.76,0,0,0,.57,8,2,2,0,0,0,1.61,9ZM1.16,6.35A1.44,1.44,0,0,1,3,5.51,1.23,1.23,0,0,1,3.76,6a1.63,1.63,0,0,1,.15,1.22A1,1,0,0,1,3.25,8a2.09,2.09,0,0,1-1,0,1.15,1.15,0,0,1-.76-.47A1.66,1.66,0,0,1,1.16,6.35Z"
            class="a"
          ></path>
          <path
            d="M19,4.81a2.28,2.28,0,0,0-.22.22,10.85,10.85,0,0,0-.91,1.17l-.59.9s-.25.21-.28.31A3.67,3.67,0,0,0,15.82,7a4.85,4.85,0,0,0-4.71,1.89L11,8.83,9.8,8.21a10.88,10.88,0,0,0-1.44-.37,12,12,0,0,0-1.47-.1l-.75-.2a.34.34,0,0,0-.34.34.36.36,0,0,0,.31.35h0c0,.09.21.15.35.22a1.37,1.37,0,0,0,.31.13c.47.11.91.27,1.37.38s.91.17,1.38.28l1.17.25.11,0,0,.08A5,5,0,0,0,11.11,13a3.48,3.48,0,0,0,.55.79l.53.47a4.18,4.18,0,0,0,.68.4,5,5,0,0,0,1.66.36h.78a5.82,5.82,0,0,0,1-.17,3.39,3.39,0,0,0,1.7-1.2c.11.12.29.36.56.66a2,2,0,0,0,.25.26q.21.18.42.33c.9.24,1-.17.94-.7L20,14a3.2,3.2,0,0,0-.42-.33l-.33-.18c-.37-.19-.67-.31-.82-.37h0a4.27,4.27,0,0,0,.4-1.12,4.88,4.88,0,0,0-.66-3.61,2.77,2.77,0,0,0-.54-.59l.22-.18.93-.79c.28-.35.61-.69.91-1.06.1-.11.19-.23.28-.35C20.24,4.44,20,4.18,19,4.81ZM14.45,15.66c0,.14,0,.28,0,.43s0,.43,0,.64.09.62.14.93.95.55,1,.09,0-.72,0-1.08a4.91,4.91,0,0,0-.06-.66,3.54,3.54,0,0,0-.11-.44C15.28,15.17,14.54,15.26,14.45,15.66Zm-3.66-1.29-.3.19c-.46.28-.92.54-1.37.84s-.88.6-1.3.92C7,17,6.16,17.68,5.37,18.39l-.25.22c-.92,1-.68,1.17.49.73L6,19.1c.51-.38,1-.73,1.55-1.1s1-.74,1.52-1.12c.67-.52,1.31-1.06,2-1.58l.33-.27C11.67,14.37,11.49,14.16,10.79,14.37Zm6.93-2.6a2.59,2.59,0,0,1-1.72,2,4.46,4.46,0,0,1-2.28,0A2.56,2.56,0,0,1,12,12.58a4,4,0,0,1-.47-2.75c.47-1.87,2.68-2.38,4.11-2a2.54,2.54,0,0,1,1.69,1.11A4,4,0,0,1,17.72,11.77Z"
            class="a"
          ></path>
        </g>
        <path
          d="M14.7,18.29a2.72,2.72,0,0,0-1,.08,2,2,0,0,0-1.5,1.34,2.74,2.74,0,0,0,.28,2,2.07,2.07,0,0,0,1.1.91,3.12,3.12,0,0,0,1.91,0,1.84,1.84,0,0,0,1-.75,2.48,2.48,0,0,0,.33-1.11.35.35,0,0,0-.33-.37.34.34,0,0,0-.37.32,1.36,1.36,0,0,1-.29.7,1,1,0,0,1-.57.34,2.27,2.27,0,0,1-1.07-.09,1.08,1.08,0,0,1-.68-.54,1.6,1.6,0,0,1-.2-1.09,1.1,1.1,0,0,1,.72-.74,1.77,1.77,0,0,1,1-.15,1.26,1.26,0,0,1,.86.43.31.31,0,0,0,.51-.34,1.75,1.75,0,0,0-.84-.72Z"
          class="a"
        ></path>
        <path
          d="M23.33.81A2.07,2.07,0,0,0,22,0a2.61,2.61,0,0,0-2.81,1.4,2.66,2.66,0,0,0,.19,1.88,2,2,0,0,0,.57.7l.78.37a3.32,3.32,0,0,0,1.62,0A2,2,0,0,0,23.66,3.2,2.6,2.6,0,0,0,23.33.81ZM22.7,2.55a.93.93,0,0,1-.66.74,2.22,2.22,0,0,1-1,0,1.15,1.15,0,0,1-.76-.47c-.86-1.29.14-2.38,1.57-2a1.19,1.19,0,0,1,.73.51A1.67,1.67,0,0,1,22.7,2.55Z"
          class="a"
        ></path>
        <path
          d="M4.38,20.32l-.52-.49A2.3,2.3,0,0,0,3,19.52a2.6,2.6,0,0,0-2.8,1.41A2.59,2.59,0,0,0,.41,22.8a1.93,1.93,0,0,0,1,1,3.19,3.19,0,0,0,2,.14,2,2,0,0,0,1.33-1.22A2.59,2.59,0,0,0,4.38,20.32ZM3.74,22a.93.93,0,0,1-.67.74,2.17,2.17,0,0,1-1,0,1.12,1.12,0,0,1-.76-.47A1.68,1.68,0,0,1,1,21.14a1.4,1.4,0,0,1,1.86-.83,1.2,1.2,0,0,1,.73.51A1.62,1.62,0,0,1,3.74,22Z"
          class="a"
        ></path>
        <path
          d="M23.49,14.73a1.58,1.58,0,0,0-.84-.55,2.28,2.28,0,0,0-2,.5l-.41.76a2.28,2.28,0,0,0,.15,1.26,1.62,1.62,0,0,0,.81.78,2.5,2.5,0,0,0,1.53.12,1.57,1.57,0,0,0,1.06-1A2.05,2.05,0,0,0,23.49,14.73Zm-.72,1.32a.53.53,0,0,1-.38.42,1.49,1.49,0,0,1-.67,0,.66.66,0,0,1-.47-.26,1,1,0,0,1-.23-.77c.1-.53.71-.68,1.23-.53a.76.76,0,0,1,.46.33A1,1,0,0,1,22.77,16.05Z"
          class="a"
        ></path>
      </g>
    </g>
  </svg>
);
